import classNames from "classnames";
import { STAFF_ROLE } from "../../commons/enum";
import StaffSignupUploadImage from "./ staff-signup-upload-image";
import { staffRoleEnum } from "./staff-sign-up.enum.ts";
import StaffSignupColorPicker from "./staff-signup-color-picker";
import StaffSignupInput from "./staff-signup-input";
import "./staff-signup.scss";

const DEFAULT_COMPANY_ADMIN_OTHER_DETAIL = [
  { title: "Calendly Url", type: "calendlyUrl" },
  {
    title: "Video Url (Youtube, Vimeo, Wistia)",
    type: "introductionVideoUrl",
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload professional photo (800x450)",
        type: "imageUrl",
        aspect: 1.5,
      },
    ],
  },
];

const COMPANY_ADMIN_DETAILS = [
  { title: "Email Address", fieldType: "email", type: "email" },
  { title: "Name", type: "brokerName" },
  { title: "Phone Number", type: "phoneNumber" },
];

const COMPANY_BRANDING = [
  {
    title: "Color Code Primary",
    inputType: "colorPicker",
    type: "primaryColor",
  },
  {
    title: "Color Code Secondary",
    inputType: "colorPicker",
    type: "secondaryColor",
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload Logo Dark(150x100)",
        type: "companyLogoDarkURL",
        aspect: 1.5,
      },
      {
        title: "Upload Logo Light (150x100)",
        type: "companyLogoURL",
        aspect: 1.5,
      },
    ],
  },
  {
    inputType: "image",
    types: [
      {
        title: "Upload Icon Dark(50x50)",
        type: "companyIconDarkURL",
        aspect: 1,
      },
      {
        title: "Upload Icon Light (50x50)",
        type: "companyIconURL",
        aspect: 1,
      },
    ],
  },
];

const COMPANY_DETALS = [
  {
    title: "Name",
    type: "companyName",
  },
  { title: "ACN (Australian Company Number)", type: "companyNumber" },
  {
    title: "Customer Support Email",
    fieldType: "email",
    type: "customerSupportEmail",
  },
  { title: "Main Business Phone Number ", type: "businessPhoneNumber" },
  {
    title: "Domain",
    inputType: "domainName",
    type: "companyDomain",
  },
];
const CONNECTIVE_DETAILS = [
  { title: "CA Number", type: "connectiveAccountId" },
  {
    title: "Company Id",
    type: "connectivePartnerId",
  },
  { title: "API Key", type: "connectiveApiKey" },
  {
    title: "API Token",
    type: "connectiveApiToken",
  },
];

const StaffSignupForm = ({ refs, formik, staffRole }) => {
  const getCompanyDetailsFieldByRole = (role) => {
    const companyDetailsList = [...COMPANY_DETALS];
    switch (role) {
      case staffRoleEnum.BROKER:
        companyDetailsList.splice(2, 0, {
          title: "Australian Credit License Number",
          type: "creditLicenseNumber",
        });
        return companyDetailsList;
      default:
        companyDetailsList.splice(2, 0, {
          title: "Australian Financial Services License Number",
          type: "financialServicesLicenseNumber",
        });
        return companyDetailsList;
    }
  };

  const generateFieldList = (fieldList) => {
    return fieldList.map((field) => {
      switch (field.inputType) {
        case "image":
          return (
            <div
              className={classNames("add-staff__field-container", {
                "professional-photo": field.types?.[0].title.includes(
                  "professional"
                ),
              })}
            >
              {field?.types?.map((fieldType) => (
                <StaffSignupUploadImage
                  params={formik.values}
                  handleUpdateParams={formik.setFieldValue}
                  key={fieldType.title}
                  type={fieldType.type}
                  errors={formik.errors}
                  title={fieldType.title}
                  isLightLogo={fieldType.title.includes("Light")}
                  aspect={fieldType.aspect}
                />
              ))}
            </div>
          );
        case "colorPicker":
          return (
            <StaffSignupColorPicker
              params={formik.values}
              handleUpdateParams={formik.setFieldValue}
              key={field.title}
              type={field.type}
              title={field.title}
            />
          );
        case "domainName":
          return (
            <StaffSignupInput
              onBlur={formik.handleBlur}
              key={field.title}
              handleUpdateParams={formik.handleChange}
              title={field.title}
              type={field.type}
              fieldType={field.fieldType}
              params={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isSubDomainInput
            />
          );

        default:
          return (
            <StaffSignupInput
              onBlur={formik.handleBlur}
              key={field.title}
              handleUpdateParams={formik.handleChange}
              title={field.title}
              type={field.type}
              fieldType={field.fieldType}
              params={formik.values}
              errors={formik.errors}
              touched={formik.touched}
            />
          );
      }
    });
  };
  const generatDetailList = () => {
    return generateFieldList(COMPANY_ADMIN_DETAILS);
  };

  const generateOtherDetailList = () => {
    return generateFieldList(DEFAULT_COMPANY_ADMIN_OTHER_DETAIL);
  };
  const generateConnectiveDetailList = () => {
    return generateFieldList(CONNECTIVE_DETAILS);
  };

  const generateCompanyBrand = () => {
    return generateFieldList(COMPANY_BRANDING);
  };
  const generateCompanyDetail = (role) => {
    const fieldList = getCompanyDetailsFieldByRole(role);
    return generateFieldList(fieldList);
  };

  return (
    <form ref={refs} className="add-staff__form" onSubmit={formik.handleSubmit}>
      <div className="add-staff__form-container">
        <div className="add-staff__title">Admin Company Details</div>
        {generatDetailList()}
        <div className="add-staff__title">Admin Advisor Content</div>
        {generateOtherDetailList(staffRole)}
        {staffRole === STAFF_ROLE.BROKER && (
          <>
            <div className="add-staff__title">Connective Details</div>
            {generateConnectiveDetailList()}
          </>
        )}
      </div>
      <div className="add-staff__form-container">
        <div className="add-staff__title">Company Details</div>
        {generateCompanyDetail(staffRole)}
        <div className="add-staff__title">Company Branding</div>
        {generateCompanyBrand()}
      </div>
    </form>
  );
};

export default StaffSignupForm;
